<template lang="pug">
  window
    template(slot="header")
      span Acceso

    v-form(@submit.prevent="requestOfType('email')")
      v-text-field(v-model.trim="form.email"
            label="Email"
            autocomplete="email"
            prepend-icon="mdi-account"
            class="mb-2"
            :error-messages="emailError"
            @blur="$v.form.email.$touch()")

      v-text-field(v-model="form.password"
            @blur="$v.form.password.$touch()"
            :error-messages="passwordError"
            label="Contraseña"
            type="password"
            autocomplete="password"
            prepend-icon="mdi-lock")

      div(class="mt-2")
        div
          router-link(:to="{name: 'register'}") Registrate
        div
          router-link(:to="{name: 'forgotPassword'}") ¿Olvidaste tu contraseña?


      v-layout(column align-center class="mt-3")
        v-flex
          v-btn(
            large
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="invalid || loading"
            @click="requestOfType('email')"
          ) Ingresar



    //template(slot="footer")
      v-layout(column align-center class="mb-2")
        v-flex(class="mb-2")
          div También puedes utilizar:
        v-flex
          //v-btn(icon class="btn-facebook" @click="requestOfType('facebook')")
            v-icon(class="white--text") mdi-facebook

          v-btn(icon class="btn-google-plus" @click="requestOfType('google')")
            v-icon(class="white--text") mdi-google-plus
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

import Window from './Window.component';

export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      errorMessages: {
        email: 'El correo es inválido',
        required: 'Este campo es requerido',
      }
    }
  },
  components: {
    Window
  },

  computed: {
    emailError() {
      const errors = []
      const email = this.$v.form.email;
      if(email.$error) {
        const messages = this.errorMessages;
        if (!email.required) errors.push(messages.required)
        if (!email.email) errors.push(messages.email)
      }

      return errors
    },
    passwordError() {
      const errors = []
      if(this.$v.form.password.$error) {
        errors.push(this.errorMessages.required)
      }

      return errors
    },

    invalid() {
      return this.$v.form.$error;
    }
  },
  props: {
    errorToDisplay: String,
    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    requestOfType(type) {

      if(type === 'email') {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
      }

      this.$emit('request', this.getTypeRequest(type));
    },

    getTypeRequest(type) {
      let requestObject = {type};

      switch(type) {
        case 'email':
          requestObject.email = this.form.email;
          requestObject.password = this.form.password;
          break;
      }

      return requestObject;
    }
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  }
}
</script>

<style scoped>

  .btn-facebook {
    background-color: #3b5998;
    color: white;
  }

  .btn-google-plus {
    background-color: #d34836;
    color: white;
  }

  .bg-virtualitour {
    background-color: #0050b3;
  }
</style>
