<template lang="pug">
div
  login(:loading="loading", @request="loginRequest")
  v-dialog(v-model="isResendEmailDialogOpen", persistent, max-width="290")
    v-card
      v-card-title.headline Verificación de email
      v-card-text Tu correo electrónico aun no ha sido verificado.
      v-card-actions
        v-spacer
        v-btn(color="primary", flat, @click="sendVerificationEmail") Reenviar</v-btn>
        v-btn(flat, @click="closeDialog") Cancelar</v-btn>
</template>

<script>
import Login from "../components/Login.component";
import firebase, { analytics } from "@/modules/common/firebase";

export default {
  name: "AccessLogin",
  components: {
    Login,
  },
  data() {
    return {
      loading: false,
      googleProvider: new firebase.auth.GoogleAuthProvider(),
      facebookProvider: new firebase.auth.FacebookAuthProvider(),
      isResendEmailDialogOpen: false,
    };
  },
  methods: {
    loginRequest(credentials) {
      this.loading = true;

      this.sendRequestOf(credentials)
        .then(this.succesLogin.bind(this, credentials))
        .catch(this.failedLogin);
    },

    async succesLogin({ type }, res) {
      this.loading = false;

      if (res.additionalUserInfo.isNewUser) {
        analytics.logEvent("sign_up", {
          method: type,
        });
      }

      if (res.user.emailVerified) {
        analytics.logEvent("login", {
          method: type,
        });
        const user = await this.$getUser();
        analytics.setUserProperties({ industry: user.industry });
        analytics.setUserId(res.user.uid);

        const redirect = this.$route.query.redirect;

        if(redirect) {
          this.$router.push(redirect);
        } else {
          this.$router.push({ name: "home" });
        }

      } else {
        this.isResendEmailDialogOpen = true;
      }
    },

    sendVericationEmail() {
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(this.sendEmailVerificationSuccess)
        .catch(this.failedLogin.bind(this));

      this.closeDialog();
    },

    sendEmailVerificationSuccess() {
      this.$notify({
        type: "success",
        text: "Hemos enviado un correo para verificar tu email",
      });
    },

    closeDialog() {
      this.isResendEmailDialogOpen = false;
      firebase.auth().signOut();
    },

    failedLogin(error) {
      this.loading = false;
      this.$notify({
        text: this.getErrorMessage(error),
        type: "error",
      });
    },

    sendRequestOf(requestObject) {
      const { email, password, type } = requestObject;
      let promise;

      switch (type) {
        case "email":
          promise = firebase.auth().signInWithEmailAndPassword(email, password);
          break;
        case "facebook":
          promise = firebase.auth().signInWithPopup(this.facebookProvider);
          break;
        case "google":
          promise = firebase.auth().signInWithPopup(this.googleProvider);
          break;
        default:
          promise = Promise.reject({ code: "NoTypeAuth" });
      }

      return promise;
    },

    getErrorMessage(error) {
      let message;

      switch (error.code) {
        // Errors that come from signInWithEmailAndPassword method
        case "auth/invalid-email":
          message = "Email no es validdo";
          break;
        case "auth/user-disabled":
          message = "El usuario ha sido deshabilitado";
          break;
        case "auth/user-not-found":
        case "auth/wrong-password":
          message = "Sus credenciales no son correctos";
          break;

        //errors that come from signInWithPopup
        case "auth/account-exists-with-different-credential":
          // TODO need to do stuff, not just a message
          message = error.message;
          break;
        case "auth/auth-domain-config-required":
          message = "authDomain configuration is invalid";
          break;
        case "auth/cancelled-popup-request":
          message = "User click very fast";
          break;
        case "auth/operation-not-allowed":
          message = "Admin needs to allow this credential in firebase";
          break;
        case "auth/operation-not-supported-in-this-environment":
          message =
            'Thrown if this operation is not supported in the environment your application is running on. "location.protocol" must be http or https.';
          break;
        case "auth/popup-blocked":
          message = "El explorador ha cerrado la ventana";
          break;
        case "auth/popup-closed-by-user":
          message = "Ha cerrado la ventana antes de tiempo";
          break;
        case "auth/unauthorized-domain":
          message = "Este dominio no ha sido autorizado";
          break;

        default:
          message = error.message || "Error desconocido.";
      }

      return message;
    },
  },
};
</script>

<style scoped>
</style>
